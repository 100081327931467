<template>
  <div class="vx-col sm:w-full md:w-full lg:w-full xl:w-full">
    <vx-card title="Informations personnelles" class="mb-4">
      <div slot="no-body">
        <div class="p-8">
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <div>
                <div class="mb-2 ml-6">Avatar</div>
                <vs-avatar class="ml-6" size="100px" :src="showAvatar()" />
                <div class="uk-margin">
                  <div uk-form-custom class="aa-vie-upload-file">
                    <input type="file" v-on:change="onFileAvatar" />
                    <button
                      class="uk-button uk-button-default"
                      type="button"
                      tabindex="-1"
                    >
                      Changer la photo
                    </button>
                    <clip-loader
                      :loading="loadingAvatar"
                      color="primary"
                    ></clip-loader>
                  </div>
                </div>
                <div class="aps-benin-v2-error-msg" v-show="avatarError"
                  >Erreur lors du téléchargement</div
                >
              </div>
            </div>
            <div class="vx-col sm:w-auto w-full mb-2">
              <div class="inline-flex">
                <vs-button class="mt-4" @click="popupChangePwdActive = true"
                  >Changer le mot de passe</vs-button
                >
                <vs-popup
                  class="holamundo"
                  title="Changer le mot de passe"
                  :active.sync="popupChangePwdActive"
                >
                  <div class="m-4">
                    <div v-show="error" class="text-danger">{{ errorMsg }}</div>
                    <div class="clearfix">
                      <div>
                        <vs-input
                          type="password"
                          data-vv-validate-on="blur"
                          v-validate="'required|min:8'"
                          name="ancien mot de passe"
                          label="Ancien mot de passe"
                          v-model="oldPassword"
                          class="w-full mt-6 mb-2"
                        />
                        <div class="aps-benin-v2-error-msg">{{
                          errors.first("ancien mot de passe")
                        }}</div>

                        <vs-input
                          ref="password"
                          type="password"
                          data-vv-validate-on="blur"
                          v-validate="'required|min:8|upCase|number|upDown'"
                          name="mot de passe"
                          label="Nouveau mot de passe"
                          v-model="password"
                          class="w-full mt-6"
                        />
                        <div style="display: flex;flex-direction: column;">
                          <span class="text-waring text-sm">
                            {{ validePass() }}
                          </span>
                          <div class="aps-benin-v2-error-msg mt-10 mb-2">{{
                            errors.first("mot de passe")
                          }}</div>
                        </div>
                        <vs-input
                          type="password"
                          v-validate="'required|min:8|confirmed:password'"
                          data-vv-validate-on="blur"
                          data-vv-as="password"
                          name="confirm_password"
                          label="Confirmer le mot de passe"
                          v-model="confirm_password"
                          class="w-full mt-4 mb-2"
                        />
                        <div
                          class="aps-benin-v2-error-msg"
                          v-if="errors.first('confirm_password')"
                          >Le mot de passe n'est pas identique</div
                        >
                      </div>
                    </div>
                    <div class="mt-8">
                      <div class="inline-flex">
                        <div>
                          <vs-button
                            class="w-full sm:w-auto"
                            :disabled="!validateFormPwd || loading"
                            @click="changePassword"
                            >Mettre à jour</vs-button
                          >
                          <vs-button
                            class="w-full sm:w-auto ml-4"
                            type="border"
                            @click="popupChangePwdActive = false"
                            >Annuler</vs-button
                          >
                        </div>
                        <div class="ml-2">
                          <clip-loader
                            :loading="loading"
                            color="primary"
                          ></clip-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </vs-popup>
              </div>
            </div>
          </div>
        </div>
        <div class="ml-8 mr-8">
          <vs-divider color="primary"></vs-divider>
        </div>

        <!-- formulaire affiche detail user -->
        <div class="p-8">
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-input
                label="Nom d'utilisateur"
                disabled
                v-model="form.username"
                class="w-full"
              />
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-input
                data-vv-validate-on="blur"
                v-validate="'required|email'"
                name="email"
                label="E-mail"
                v-model="form.email"
                class="w-full"
              />
              <div class="aps-benin-v2-error-msg">{{
                errors.first("email")
              }}</div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-input
                data-vv-validate-on="blur"
                v-validate="'required|min:3'"
                name="prénom"
                label="Prénom(s)"
                v-model="form.person.firstname"
                class="w-full"
              />
              <div class="aps-benin-v2-error-msg">{{
                errors.first("prénom")
              }}</div>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-input
                data-vv-validate-on="blur"
                v-validate="'required|min:3'"
                name="nom"
                label="Nom"
                v-model="form.person.lastname"
                class="w-full"
              />
              <div class="aps-benin-v2-error-msg">{{ errors.first("nom") }}</div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-input
                type="date"
                name="date de naissance"
                label=" Date de naissance (jj/mm/aaaa)"
                v-model="form.person.birthday"
                class="w-full aa-vie-disable-input-date"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mt-4 mb-2">
              <div>
                <label for class="vs-input--label">Sexe</label>
              </div>
              <div class="flex">
                <ul class="centerx flex">
                  <li class="pr-2 w-full">
                    <vs-radio
                      v-model="form.person.gender"
                      vs-value="M"
                      name="masculin"
                      >Masculin</vs-radio
                    >
                  </li>
                  <li class="w-full">
                    <vs-radio
                      v-model="form.person.gender"
                      name="feminin"
                      vs-value="F"
                      >Feminin</vs-radio
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mt-4 mb-2">
              <div class="inline-flex">
                <div>
                  <vs-button
                    class="mr-3"
                    :disabled="!validateFormUser || loading"
                    @click="updateUser"
                    >Mettre à jour</vs-button
                  >
                </div>
                <div class="ml-2">
                  <clip-loader :loading="loading" color="primary"></clip-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { en, fr } from "vuejs-datepicker/dist/locale";
import { mixin } from "@/mixins/mixin";
import { mapGetters, mapActions } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  data() {
    return {
      fr: fr,
      form: {
        email: "",
        username: "",
        person: {
          firstname: "",
          lastname: "",
          birthday: ""
        }
      },
      password: "",
      oldPassword: "",
      confirm_password: "",
      popupChangePwdActive: false,
      loadingAvatar: false,
      loading: false,
      error: false,
      errorMsg: "",
      avatarError: false,
      logoError: false
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.$validator.extend("upCase", {
      getMessage: () => "Veuillez compléter au moins une lettre majuscule",
      validate: value => value.match(/[A-Z]/g) !== null
    });
    this.$validator.extend("number", {
      getMessage: () => "Veuillez compléter au moins un chiffre",
      validate: value => value.match(/[0-9]/g) !== null
    });
    this.$validator.extend("upDown", {
      getMessage: () => "Veuillez compléter au moins une lettre minuscule",
      validate: value => value.match(/[a-z]/g) !== null
    });
  },
  mounted() {
    if (this.user) {
      this.form.email = this.user.email;
      this.form.username = this.user.username;
      this.form.person.birthday = this.user.person.birthday;
      this.form.person.firstname = this.user.person.firstname;
      this.form.person.lastname = this.user.person.lastname;
      this.form.person.gender = this.user.person.gender;
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "userId"]),
    validateFormPwd() {
      return (
        !this.errors.any() &&
        this.oldPassword != "" &&
        this.password != "" &&
        this.confirm_password != ""
      );
    },
    validateFormUser() {
      return (
        !this.errors.any() &&
        this.form.email != "" &&
        this.form.username != "" &&
        this.form.person.firstname != "" &&
        this.form.person.firstname != ""
      );
    }
  },
  methods: {
    ...mapActions("auth", [
      "saveUserInfos",
      "changePwd",
      "applyPutUser",
      "logout"
    ]),
    ...mapActions("general", ["applyUploadFile"]),
     acceptAlert(data) {
      let newdata = {...data}
      this.$vs.notify(newdata);
    },

    validePass() {
      let pass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
      if (!this.password.match(pass)) {
        return "Le mot de passe doit contenir 8 caractères minimum avec au moins une lettre minuscule, majuscule et un chiffre";
      }
    },
    errorClass(rule) {
      return {
        error: this.errors.firstByRule("mot de passe", rule)
      };
    },
    showAvatar() {
      if (this.user.person.avatar) {
        return `${this.$config.serverURL}/downloads/${this.user.person.avatar}`;
      } else {
        return require("@/assets/images/avatar/man.png");
      }
    },
    async changePassword() {
      if (!this.validateFormPwd) return false;

      let userData = {
        username: this.user.username,
        oldPassword: this.oldPassword,
        password: this.password
      };

      this.loading = true;
      try {
        await this.changePwd(userData);

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
        this.popupChangePwdActive = false;
        this.acceptAlert(mixin.methods.message("users", "mdp").success);

        setTimeout(async () => {
          if (!this.popupChangePwdActive) {
            await this.logout();
            this.$router.push({ name: "Login" });
          }
        }, 300);
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupChangePwdActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else if (err.response.status === 500) {
            this.errorMsg = "Votre mot de passe est incorrect";
            this.acceptAlert(mixin.methods.message("users", "mdp").error);
          } else {
            err.response.data.errors.forEach(item => {
              /*switch (item.code) {
                default:
                  break;
              }*/
            });
          }
        }
      }
    },
    async updateUser() {
      if (!this.validateFormUser) return false;

      let userData = {
        userId: this.userId,
        email: this.form.email,
        ...this.form.person
      };

      this.loading = true;
      try {
        let newUserData = await this.applyPutUser(userData);
        this.saveUserInfos(newUserData.data);

        this.loading = false;
        this.error = false;
        this.errorMsg = "";

        this.acceptAlert(mixin.methods.message("users", "updateUser").success);
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.acceptAlert(mixin.methods.message("users", "notFound").error);
                  break;
                case "userNameExistError":
                  this.acceptAlert(mixin.methods.message("users", "nameExist").error);
                  break;
                case "sendMailError":
                  this.acceptAlert(mixin.methods.message("users", "mailError").error);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("users", "updateUser").error
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async onFileAvatar(evt) {
      let formData = new FormData();
      formData.append("uri", evt.target.files[0]);
      this.loadingAvatar = true;
      this.avatarError = false;

      try {
        let fileDataUp = await this.applyUploadFile(formData);

        let userData = {
          userId: this.userId,
          email: this.user.email,
          ...this.user.person,
          avatar: fileDataUp.data[0].uploadedName
        };
        let newUserData = await this.applyPutUser(userData);

        if (newUserData.data.person.avatar !== this.user.person.avatar) {
          // this.avatarUser = `${this.$config.serverURL}/downloads/${newUserData.data.person.avatar}`;
        }
        this.saveUserInfos(newUserData.data);

        this.acceptAlert(mixin.methods.message("users", "updateAvatar").success);
        this.loadingAvatar = false;
      } catch (err) {
        this.loadingAvatar = false;
        this.avatarError = true;
      }
    }
  },
  watch: {},
  components: {
    ClipLoader
  }
};
</script>
